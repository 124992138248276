body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.banner {
  padding: 2em;
}

.campaign-dashboard button {
  margin: 5px;
}

.campaign-active {
  margin: auto 15px;
}

.euiBasicTable .euiTableCellContent {
  justify-content: space-between;
}

.user-actions button {
  margin: 0.5em;
}

:root {
  align-items: center;
  width: 100%;
  height: 100%;
}
